<template>
  <merchant-layout title="Votre établissement" :is-loading="!settings||!currentMerchant">

    <!-- <i class="material-icons text-gray-500 text-xl cursor-pointer" @click="resetTour">question_mark</i> -->

    <div class="grid xl:grid-cols-2 gap-4 items-start">
      <page-block title="Présentation" v-if="settings">
        <div class="bg-primary/5 px-5 py-4 rounded-md">
          <span class="text-gray-700 ">Quelques mots sur votre établissement pour le présenter aux clients qui ne le connaissent pas encore</span>
        </div>

        <div class="grid md:grid-cols-2 mt-4 gap-4">
          <div>
            <div class="flex justify-between">
              <label class="font-bold">Nom commercial <span class="text-red-500">*</span></label>
              <div @click="showIntercom()" v-if="!currentMerchant.features.merchantNameEditable" class="locked-feature-badge"><i class="material-icons text-sm mr-1">lock</i><span class="text-sm font-medium">Support</span></div>
            </div>
            <input type="text" :disabled="!currentMerchant.features.merchantNameEditable" class="form-input mt-2" :class="{'form-input-error': errors.some(e => e.error === 'name')}" v-model="settings.name">
            <small class="text-red-500" v-if="errors.some(e => e.error === 'name')">{{errors.find(e => e.error === 'name').message}}</small>
          </div>
          <div>
            <label class="font-bold">Slogan</label>
            <input type="text" class="form-input mt-2" v-model="settings.subtitle" :class="{'form-input-error': errors.some(e => e.error === 'subtitle')}">
            <small class="text-red-500" v-if="errors.some(e => e.error === 'subtitle')">{{errors.find(e => e.error === 'subtitle').message}}</small>
          </div>

          <div class="md:col-span-2 mt-2">
            <label class="font-bold">Adresse complète<span class="text-red-500">*</span></label>
            <address-input class="mt-2" v-model="settings.addressGoogleId" :field-value="merchantAddressValue"/>
            <small class="text-red-500" v-if="errors.some(e => e.error === 'address')">{{errors.find(e => e.error === 'address').message}}</small>
          </div>
          <div class="mt-2 pb-3">
            <label class="font-bold flex items-center mb-2">Téléphone</label>
            <phone-input v-model="settings.phone" container-class="h-10 rounded-md overflow-hidden border-gray-300 border flex"/>
            <small class="text-red-500" v-if="errors.some(e => e.error === 'phone')">{{errors.find(e => e.error === 'phone').message}}</small>

            <label class="font-medium flex items-center mt-3"><input type="checkbox" v-model="settings.hasPublicPhone" class="form-checkbox mr-2">Afficher le téléphone sur la page</label>
          </div>
          <div class="mt-2">
            <div class="flex justify-between">
              <label class="font-bold flex items-center">Lien de votre page</label>
              <div @click="unlockFeature" class="locked-feature-badge"><i class="material-icons text-sm mr-1">lock</i><span class="text-sm font-medium">{{ $t('system.limited') }}</span></div>
            </div>
            <input type="text" class="form-input mt-2" :value="'delicity.com/'+settings.slug" disabled>
            <small class="text-red-500" v-if="errors.some(e => e.error === 'slug')">{{errors.find(e => e.error === 'slug').message}}</small>

            <div class="mt-3 flex gap-1">
              <a :href="baseUrl+'/'+currentMerchant.slug" target="_blank" class="text-primary underline font-medium">Voir la page</a>·<button @click="openMainMerchantQrCode" class="text-primary underline font-medium">QR Code</button>
            </div>
          </div>
          <div class="md:col-span-2 mt-2">
            <div class="flex justify-between">
              <label class="font-bold flex items-center flex-1">Description de votre établissement</label>
              <span class="mr-2 text-gray-600" v-if="currentMerchant.features.merchantDescriptionHasMaxCharEnabled" :class="{'font-bold text-red-500': currentMerchant.quota.merchantDescriptionMaxChar === (settings&&settings.description ? settings.description.length : 0)}">{{settings&&settings.description ? settings.description.length : 0}}/{{currentMerchant.quota.merchantDescriptionMaxChar}}</span>
              <div @click="unlockFeature" v-if="currentMerchant.features.merchantDescriptionHasMaxCharEnabled" class="locked-feature-badge"><i class="material-icons text-sm mr-1">lock</i><span class="text-sm font-medium">{{ $t('system.limited') }}</span></div>
            </div>
            <textarea class="form-input mt-2 py-3 min-h-[100px]" :class="{'form-input-error': errors.some(e => e.error === 'description')}" :maxlength="currentMerchant.features.merchantDescriptionHasMaxCharEnabled ? currentMerchant.quota.merchantDescriptionMaxChar : null" v-model="settings.description"></textarea>
            <small class="text-red-500" v-if="errors.some(e => e.error === 'description')">{{errors.find(e => e.error === 'description').message}}</small>
          </div>
          <div class="md:col-span-2 mt-2">
            <div class="flex justify-between">
              <label class="font-bold flex items-center flex-1">Mot-clés</label>
              <div @click="unlockFeature" v-if="!currentMerchant.features.keywordEditionEnabled" class="locked-feature-badge"><i class="material-icons text-sm mr-1">lock</i><span class="text-sm font-medium">{{ $t('system.limited') }}</span></div>
            </div>
            <input type="text" class="form-input mt-2" v-model="settings.keywords" :disabled="!currentMerchant.features.keywordEditionEnabled">
            <small class="text-red-500" v-if="errors.some(e => e.error === 'keywords')">{{errors.find(e => e.error === 'keywords').message}}</small>
          </div>

          <div class="md:col-span-2 mt-2">
            <label class="font-bold">Catégories</label>
            <category-selector class="mt-2" v-model="settings.categories"/>
          </div>
        </div>

      </page-block>

      <page-block title="Médias">
        <div class="bg-primary/5 px-5 py-4 rounded-md">
          <span class="text-gray-700 ">Donnez envie aux consommateurs de venir et de commander dans votre établissement avec des photos / des vidéos de qualité. </span>
        </div>

        <div class="grid sm:grid-cols-2 mt-4 gap-4">

          <div class="grid grid-cols-3 gap-4">
            <div>
              <label class="font-bold">Logo</label>
              <!-- :class="[settings.logo ? 'items-start justify-end' : 'items-center justify-center']" -->
              <label for="logo"
                     class="h-24 xl:h-[6rem] rounded-md border flex mt-2 bg-center bg-cover cursor-pointer relative items-center justify-center"
                     :style="'background-image: url('+settings.logo+')'">
                <i class="material-icons text-gray-100 absolute opacity-80" v-if="settings.logo">upload</i>
                <i class="material-icons text-gray-600" v-if="!settings.logo">upload</i>
                <div class="h-7 w-7 bg-red-600 text-white flex items-center justify-center rounded-full -right-2 -top-2 absolute" @click.prevent="deleteLogo" v-else><i class="material-icons text-lg cursor-pointer">delete</i></div>
              </label>
              <input type="file" @input="uploadLogo" class="hidden" id="logo">
            </div>
            <div class="col-span-2" >
              <label class="font-bold truncate">Image de couverture</label>
              <label for="banner"
                     :class="[settings.banner ? 'items-start justify-end' : 'items-center justify-center']"
                     class="h-24 xl:h-[6rem] rounded-md border flex  mt-2 bg-center bg-cover cursor-pointer relative items-center justify-center"
                     :style="'background-image: url('+settings.banner+')'">
                <i class="material-icons text-gray-100 absolute opacity-80" v-if="settings.banner">upload</i>
                <i class="material-icons text-gray-600" v-if="!settings.banner">upload</i>
                <div class="h-7 w-7 bg-red-600 text-white flex items-center justify-center rounded-full -right-2 -top-2 absolute" @click.prevent="deleteBanner" v-else><i class="material-icons text-lg cursor-pointer">delete</i></div>
              </label>
              <input type="file" @input="uploadBanner" class="hidden" id="banner">
            </div>
          </div>



          <div>
            <label class="font-bold">Menu PDF</label>
            <div class="grid grid-cols-3 gap-4 mt-2">

              <div class="h-24 xl:h-[6rem] rounded-md border flex items-center justify-center relative" v-for="pdf in settings.pdfMenu">
                <button @click="removePdf(pdf.id)" class="cursor-pointer h-7 w-7 bg-red-600 text-white flex items-center justify-center rounded-full absolute -right-2 -top-2"><i class="material-icons text-lg">delete</i></button>
                <a :href="pdf.url" target="_blank" class="underline text-primary font-bold">Ouvrir</a>
              </div>

              <label for="upload_pdf" class="h-24 xl:h-[6rem] rounded-md border flex items-center justify-center cursor-pointer" v-if="(currentMerchant.quota.maxMerchantPdfFiles-settings.pdfMenu.length)>0" v-for="i in (currentMerchant.quota.maxMerchantPdfFiles-settings.pdfMenu.length)">
                <i class="material-icons text-gray-600">upload</i>
                <input type="file" id="upload_pdf" class="hidden" accept="application/pdf" @input="uploadPdf">
              </label>
              <div class="h-24 xl:h-[6rem] rounded-md border flex items-center justify-center bg-gray-100 cursor-pointer" @click="unlockFeature" v-for="i in (systemQuotas.maxMerchantPdfFiles-currentMerchant.quota.maxMerchantPdfFiles)">
                <i class="material-icons text-white bg-primary text-lg w-7 h-7 flex items-center justify-center rounded-full">lock</i>
              </div>

            </div>
          </div>



          <div class="sm:col-span-2 mt-2">
            <label class="font-bold">Photos</label>

            <div class="grid grid-cols-3 sm:grid-cols-6 gap-4 mt-2">

              <label :for="'replaceImageGallery'+image.id" class="h-24 xl:h-[6rem] rounded-md border flex items-center justify-center bg-cover relative bg-center cursor-pointer"
                     v-for="image in settings.imageGallery" :style="'background-image: url('+image.previewUrl+')'">
                <input type="file" :id="'replaceImageGallery'+image.id" class="hidden" @change="replaceGalleryImage($event, image.id)">
                <div class="h-7 w-7 bg-red-600 text-white flex items-center justify-center rounded-full absolute -right-2 -top-2 cursor-pointer" @click.prevent="deleteImage(image)"><i class="material-icons text-lg cursor-pointer">delete</i></div>
                <i class="material-icons text-gray-100 absolute opacity-80">upload</i>
              </label>

              <label for="uploadGalleryImage" class="h-24 xl:h-[6rem] rounded-md border flex items-center justify-center" v-if="(currentMerchant.quota.maxMerchantGalleryImage-settings.imageGallery.length)>0" v-for="i in (currentMerchant.quota.maxMerchantGalleryImage-settings.imageGallery.length)">
                <input type="file" id="uploadGalleryImage" class="hidden" @change="uploadGalleryImage($event)">
                <i class="material-icons text-gray-600">upload</i>
              </label>

              <div class="h-24 xl:h-[6rem] rounded-md border flex items-center justify-center bg-gray-100 cursor-pointer" @click="unlockFeature" v-for="i in (systemQuotas.maxMerchantGalleryImage-currentMerchant.quota.maxMerchantGalleryImage)">
                <i class="material-icons text-white bg-primary text-lg w-7 h-7 flex items-center justify-center rounded-full">lock</i>
              </div>

            </div>
          </div>

          <div class="sm:col-span-2 mt-2">
            <label class="font-bold">Vidéos</label>
            <div class="grid grid-cols-3 sm:grid-cols-6 gap-4 mt-2">
              <a target="_blank" :href="'https://forms.delicity.com/marketing/shooting-photo-video?merchant_id='+currentMerchant.id+'&merchant_name='+currentMerchant.name" class="h-24 xl:h-[6rem] rounded-md border flex items-center justify-center" v-for="i in currentMerchant.quota.maxMerchantVideos">
                <i class="material-icons text-gray-600">add</i>
              </a>
              <!--
              <div class="h-24 xl:h-[6rem] rounded-md border flex items-center justify-center bg-gray-100 cursor-pointer" @click="unlockFeature" v-for="i in (systemQuotas.maxMerchantVideos-currentMerchant.quota.maxMerchantVideos)">
                <i class="material-icons text-white bg-primary text-lg w-7 h-7 flex items-center justify-center rounded-full">lock</i>
              </div>
              -->
            </div>
          </div>
        </div>

      </page-block>
      <div class="xl:col-span-2">
        <button class="btn bg-primary text-white mt-3" @click="save" :disabled="isButtonLoading">
          <span v-if="!isButtonLoading">{{$t('cta.save')}}</span>
          <loader v-else class="h-4 w-4"/>
        </button>
      </div>
    </div>
  </merchant-layout>
</template>

<script>
import {mediaUploadImage} from "@/services/MediaService";
import {mapGetters} from "vuex";
import {
  getMerchantSettings, getMerchantTrackingQrCodeLink,
  removeMerchantDocument,
  updateMerchantGallery,
  removeMerchantGallery,
  uploadMerchantDocument,
  removeLogo,
  removeBanner
} from "@/services/MerchantService";
import {unlockFeature} from "@/services/Subscription";
import AddressInput from "@/components/Ui/Form/AddressInput.vue";
import {errorToast, showIntercom, successToast} from "@/services/SystemService";
import PhoneInput from "@/components/Ui/Form/PhoneInput.vue";
import CategorySelector from "@/components/Merchant/CategorySelector.vue";
import Loader from "@/components/Ui/Elements/Loader.vue";
import {siteTour, resetTour} from "@/services/TourService";


export default {
  components: {Loader, CategorySelector, PhoneInput, AddressInput},
  setup(){
    const baseUrl = import.meta.env.VITE_FRONT_BASE_URL;
    return {baseUrl};
  },
  data(){
    return {
      settings: false,
      addressId: null,
      errors: [],
      isButtonLoading: false,
      fileLoading: [],
    }
  },
  methods: {
    showIntercom,
    unlockFeature,

    async openMainMerchantQrCode(){
      const link = await getMerchantTrackingQrCodeLink(this.currentMerchant.id);
      window.open(`https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=${link}&chld=L|1&choe=UTF-8`, '_blank');
    },

    async replaceGalleryImage(e,image){
      console.log(image);
      const imageData = await mediaUploadImage(e, this.currentMerchant.id, 'merchant_gallery');
      this.settings.imageGallery = await updateMerchantGallery(this.currentMerchant.id, imageData, image);
    },

    async uploadGalleryImage(e){
      const imageData = await mediaUploadImage(e, this.currentMerchant.id, 'merchant_gallery');
      this.settings.imageGallery = await updateMerchantGallery(this.currentMerchant.id, imageData, null);
    },

    validate(){
      this.errors = [];
      if(!this.settings.name) this.errors.push({error: 'name', message: this.$t('errors.name_mandatory')});
      if(!this.settings.subtitle) this.settings.subtitle = '';
      if(!this.settings.addressGoogleId && !this.settings.address) this.errors.push({error: 'address', message: this.$t('errors.address_mandatory')});
      return this.errors.length===0;
    },

    async save(){
      if(!this.validate()) return;
      this.isButtonLoading = true;

      await this.$store.dispatch('updateMerchantAction', {
        merchantId: this.currentMerchant.id,
        data: this.settings
      })
          .then(() => {
            successToast(this.$t('messages.merchant_settings_updated'));
            if(this.settings.name && this.settings.description && this.settings.subtitle && this.settings.logo && this.settings.banner){
              this.$store.commit('SET_MERCHANT_ONBOARDING_VALUE', {type:'merchantSettings', value: true})
            }
          })
          .catch(e => {
            if(e.response && e.response.data.error && e.response.data.error === "permission_error:description_too_long") {
              this.errors.push({error: 'description', message: this.$t('errors.description_too_long', {max: this.currentMerchant.quota.merchantDescriptionMaxChar})});
            }
            else if(e.response && e.response.data.error && this.$te('errors.'+e.response.data.error)){
              errorToast(this.$t('errors.' + e.response.data.error));
            }
            else{
              errorToast(this.$t('errors.error_occurred'));
            }
          })

      this.isButtonLoading = false;
    },
    async uploadLogo(event){
      this.fileLoading.push('logo');
      await mediaUploadImage(event, this.currentMerchant.id, 'merchant-logo', ['150x150', '800x800'])
          .then((result) => {
            this.settings.logo = result.find(e=>e.size==="150x150").url;
            this.settings.logoImageData = result;
          })
          .catch((e) => {
            console.log(e)
          })
      this.fileLoading = this.fileLoading.filter(e=>e!=='logo');
    },
    async uploadBanner(event){
      this.fileLoading.push('banner');
      await mediaUploadImage(event, this.currentMerchant.id, 'merchant-banner', ['600x337', '1120x630'], 0.5625)
          .then((result) => {
            this.settings.banner = result.find(e=>e.size==="600x337").url;
            this.settings.bannerImageData = result;
          })
          .catch((e) => {
            console.log(e)
          })
      this.fileLoading = this.fileLoading.filter(e=>e!=='banner');
    },
    deleteImage(image) {
      removeMerchantGallery(this.currentMerchant.id, image.id)
      this.settings.imageGallery = this.settings.imageGallery.filter(img => img.id !== image.id)
    },
    deleteLogo() {
      this.settings.logo = null;
      removeLogo(this.currentMerchant.id);
    },
    deleteBanner() {
      this.settings.banner = null;
      removeBanner(this.currentMerchant.id);
    },
    async uploadPdf(ev, id=null){
        await uploadMerchantDocument(ev, this.currentMerchant.id, 'pdf-menue', id);
    },
    async removePdf(id){
      this.settings.pdfMenu = this.settings.pdfMenu.filter(e=>e.id!==id);
      await removeMerchantDocument(this.currentMerchant.id, id);
    },
    resetTour() {
      resetTour('tour_merchantsettings')
    }
  },
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant',
      systemQuotas: 'system/quota'
    }),
    merchantAddressValue(){
      return this.settings.address ?
          (this.settings.address ? this.settings.address.address + ', ' + this.settings.address.city : null)
          : null;
    }
  },
  async mounted() {
    if(!this.currentMerchant) await this.$store.dispatch('getMerchantsAction');
    this.settings = await getMerchantSettings(this.currentMerchant.id);
    this.settings.addressGoogleId = null;

    siteTour(this.$route.name)
  }
}
</script>
