import {getCurrentAccount} from "@/services/AccountService";

export default {
    namespaced: true,
    state: {
        account: null,
    },
    mutations: {
        SET_ACCOUNT(state, account) {
            state.account = account;
        }
    },
    getters: {
        account: state => state.account
    },
    actions: {
        async getAccount({ commit }) {
            const data = await getCurrentAccount();
            commit('SET_ACCOUNT', data);
            window.Intercom('boot', {
                app_id: 'jf5l8hke',
                email: data.email,
                name: data.name,
                user_id: data.id,
                created_at: data.created_at,
            });
            return data;
        },
    }
}
