import axios from "@/axios-instance";

export function getMerchantOrders(merchantId, page, filters){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/orders`, {page, filters})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function getMerchantPreorders(merchantId, page){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/orders/preorders`, {page})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function getMerchantOrder(merchantId, orderId){
    return new Promise(((resolve, reject) => {
        console.log(`/v1/merchants/${merchantId}/orders/${orderId}`);
        axios.get(`/v1/merchants/${merchantId}/orders/${orderId}`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function downloadOrderInvoices(merchantId, orderIds){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/orders/invoices/download`, {orderIds},{responseType: 'blob'})
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;

                let filename;
                if(orderIds.length === 1){
                    filename = 'commande-'+orderIds[0];
                }
                else{
                    filename = 'export-factures-delicity';
                }

                link.setAttribute('download', `${filename}.zip`);
                document.body.appendChild(link);
                link.click();
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            })
    }));
}



export function updateOrderSettings(merchantId, settings){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/orders/settings`, settings)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}


export function cancelOrder(merchantId, orderId, reason){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/orders/${orderId}/cancel`, {reason})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function confirmPreorder(merchantId, orderId, datetime){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/orders/${orderId}/confirm_preorder`, {datetime})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}
