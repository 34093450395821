import axios from "@/axios-instance";
import { useToast } from "vue-toastification";

export function getSystemSettings(){
    return new Promise(((resolve, reject) => {
        axios.get(`/v1/merchants/settings`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function getMerchantCategories(){
    return new Promise(((resolve, reject) => {
        axios.get(`/v1/merchants/merchant_categories`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}


export function errorToast(message){
    const toast= useToast();
    toast.error(message);
}

export function successToast(message){
    const toast= useToast();
    toast.success(message);
}

export function showArticle(id){
    console.log('Show Article');
    window.Intercom("showArticle", id);
}
export function showIntercom(){
    window.Intercom("show");
}

export function popupCenter(url, title, w, h){
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(url, title,
      `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    )

    if (window.focus) newWindow.focus();
}
